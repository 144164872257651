console.log('Webpage created with 🫶🏻 by @jujogi');

import ScrollReveal from 'scrollreveal';

ScrollReveal().reveal('.slider__image', {
  opacity: 0, // Starts invisible
  duration: 300, // Duration of the fade
  delay: 0, // No delay, makes the image appear first
  easing: 'ease-in-out', // Smooth transition
});

ScrollReveal().reveal('.slider__information', {
  opacity: 0, // Starts invisible
  duration: 1000, // Duration of the fade
  easing: 'ease-in-out', // Smooth transition,
  delay: 150,
});

ScrollReveal().reveal('.services__item', {
  distance: '50px', // Moves 50px into view
  origin: 'bottom', // Animates from the bottom
  opacity: 0, // Starts fully transparent
  duration: 800, // Animation lasts 800ms
  interval: 200, // Staggers each item by 200ms
});

ScrollReveal().reveal('.about-us__image', {
  opacity: 0, // Starts invisible
  duration: 1000, // Duration of the fade
  easing: 'ease-in-out', // Smooth transition,
  delay: 150,
});
